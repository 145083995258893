//page
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6
  color: #19063D;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: 600;
  margin-top: 24px;
h1,.h1
  font-size: 48px;
  line-height: 60px;
  @media only screen and (max-width: 976px)
    font-size: 36px;
    line-height: 44px;
h2,.h2
  font-size: 36px;
  line-height: 44px;
  @media only screen and (max-width: 976px)
    font-size: 30px;
    line-height: 38px;
h3,.h3
  font-size: 30px;
  line-height: 38px;
  @media only screen and (max-width: 976px)
    font-size: 24px;
    line-height: 32px;
h4,.h4
  font-size: 24px;
  line-height: 32px;
  @media only screen and (max-width: 976px)
    font-size: 14px;
    line-height: 24px;
h5,.h5
  font-size: 14px;
  line-height: 24px;
  @media only screen and (max-width: 976px)
    font-size: 10.5px;
    line-height: 18px;
p
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #514468;
  margin-top:18px;
  @media only screen and (max-width: 976px)
    font-size: 13.5px;
    line-height: 21px;
  @media only screen and (max-width: 767px)
    font-size: 18px;
    line-height: 28px;

strong, a, span
  font-size: inherit;

.no-pagging
  padding 0!important

.margin-0-auto
  margin: 0 auto

a
  color: #713DDD;
  font-size: inherit;

ol
  list-style: decimal;
  padding-left: 24px;
  li
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #514468;
    @media only screen and (max-width: 976px)
      font-size: 13.5px;
      line-height: 21px;
    @media only screen and (max-width: 767px)
      font-size: 18px;
      line-height: 28px;

.rtl
  .wp-block-cover-image .wp-block-cover__image-background, .wp-block-cover-image video.wp-block-cover__video-background, .wp-block-cover .wp-block-cover__image-background, .wp-block-cover video.wp-block-cover__video-background
    transform: rotateY(180deg);

//wp-blocks
.wp-block-uagb-info-box
  &.purple-btn
    .wp-block-button__link
      padding: 16px 28px;
      background: #713ddd;
      border: 1px solid #713ddd;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #fff;
      box-shadow: 0 24px 38px -4px rgba(0,0,0,.1);
      border-radius: 42px;
      position: relative;
      width: 100%;
      max-width: 328px;
      @media only screen and (max-width: 767px)
        max-width: 343px;
      span
        width 100%
      &:after
        content: "";
        width: 40px;
        height: 38px;
        background: url(../img/btn-orange-arrow.svg) center no-repeat;
        top: 14px;
        right: 28px;
        position: absolute;
        display: inline-block;
        background-size: contain;

.wp-block-uagb-container
  //border: 1px solid gray;

.wp-block-cover
  padding: 0;

.lang-wrapper
  position: relative

.rtl
  .dropdown-content--lang
    left:-60px!important

.rtl
  .lang-item
    a
      img
        border-radius 50%
        margin-left: 8px

//.rtl
//  transform: scaleX(-1);
//  text-align: right;
//  .s-header__block-title,
//  .s-header__ptetitle,
//  .s-header__block-text,
//  .brand-logo,
//  .s-widget__logo-wrap,
//  .s-our-story__title--small,
//  .s-our-story__pretitle,
//  .s-team__pretitle,
//  .s-team__description,
//  .s-team__block-name,
//  .s-team__block-position,
//  .s-open-position__pretitle,
//  .s-open-position__description,
//  .sub-menu,
//  .menu-item,
//  .s-footer__logo-text,
//  .s-footer__logo,
//  .s-footer__copyright,
//  .s-our-story__description,
//  .modal-section-form__bootom-text,
//  .modal-section-banner__title,
//  .modal-section-banner__text,
//  .modal-section-banner__avatars-block,
//  .modal-section-form__copyright,
//  .s-footer__links-wrap .sub-menu .menu-item,
//  .sidenav .sidenav-content .top-menu__link-wrap,
//  .s-header__text,
//  .s-feature__block-item-title,
//  .s-feature__block-item-text,
//  .s-reviews__slide-title,
//  .s-reviews__slide-text,
//  .s-faq__description,
//  .collapsible-body__text,
//  .collapsible-header__title,
//  .wpforms-container .wpforms-form .wpforms-submit-container .wpforms-submit,
//  .s-posts__tab-result-block-meta,
//  .s-posts__tab-result-block-title,
//  .s-posts__tab-result-block-tags span,
//  .wp-post-image,
//  iframe,
//  a,
//  p,
//  ul:not(.sidenav):not(.dropdown-content),
//  ol,
//  h1,
//  h2,
//  h3,
//  h4,
//  h5,
//  h6,
//  li,
//  label,
//  input,
//  textarea,
//  span
//    //text-align: right
//    transform: scaleX(-1)!important;
//    max-width inherit
//  //.has-text-align-center,
//  //.uagb-infobox-icon-above-title
//  //  text-align:center!important
//
//
//  .body .iti .iti__country, div.wpforms-container .wpforms-form .iti .iti__country, div.wpforms-container-full .wpforms-form .iti .iti__country, #wpforms-conversational-form-page .iti .iti__country,
//  .s-feature__block-item-link,
//  .s-header__started-btn,
//  .s-header__work-btn,
//  .top-menu__lang,
//  .s-footer__links-wrap .menu-item
//    text-align: right
//    transform: scaleX(1)!important;
//
//  .sidenav .sidenav-content .top-menu__collapse li .collapsible-header
//    justify-content flex-end

//body.rtl .wpforms-field-phone input[type=tel]
//    text-align: left!important

.padding-default
  padding-top: 96px
  padding-bottom: 96px
  @media only screen and (max-width: 976px)
    padding-top: 72px
    padding-bottom: 72px
  @media only screen and (max-width: 767px)
    padding-top: 64px
    padding-bottom: 64px

.rtl
  .s-feature__block-item-link
    img
      transform: scaleX(-1);
  .s-header__work-btn
    .play-wrap
      margin-left: 16px;
      margin-right: inherit;
  .s-header__started-btn
    .more-wrap
      margin-right: 12px;
      margin-left: inherit;
      transform: scaleX(-1);
  .s-header__work-btn
    margin-right: 28px
    margin-left: 0
  &.page-template-blog .entry-meta
    text-align: right
  &.page-template-blog .s-posts__tab-result-block-text-wrap:after
    transform: scaleX(-1);
    left: 0;
    right: auto;
  &.s-posts__tabs
    margin-left: auto
    margin-right: 0
  .modal
    left: auto!important;
    right: 50%;
    transform: translate(50%,-50%)!important;
  .collapsible li
    .collapsible-header
      padding: 0 0 0 46px;
      &__plus
        left: 0
        right: inherit!important
    .collapsible-body
      padding: 8px 0 0 46px;
  .s-header
    &__arrow-right
      left: -20px
      right: inherit;
      transform: scaleX(-1);
      @media only screen and (max-width: 1100px)
        left: -90px;
        right: inherit;
      @media only screen and (max-width: 600px)
        display: none
    &__arrow-left
      left: 100px
      right: inherit;
      transform: scaleX(-1);
      @media only screen and (max-width: 1100px)
        left: -44px;
        right: inherit;
      @media only screen and (max-width: 600px)
        display: none
    &__arrow-heart
      left: 40px
      right: inherit;
      transform: scaleX(-1);
      @media only screen and (max-width: 1100px)
        left: -60px;
        right: inherit;
      @media only screen and (max-width: 600px)
        left: 10px
        right: inherit;
