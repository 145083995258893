h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #19063d;
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: 600;
  margin-top: 24px;
}
h1,
.h1 {
  font-size: 48px;
  line-height: 60px;
}
@media only screen and (max-width: 976px) {
  h1,
  .h1 {
    font-size: 36px;
    line-height: 44px;
  }
}
h2,
.h2 {
  font-size: 36px;
  line-height: 44px;
}
@media only screen and (max-width: 976px) {
  h2,
  .h2 {
    font-size: 30px;
    line-height: 38px;
  }
}
h3,
.h3 {
  font-size: 30px;
  line-height: 38px;
}
@media only screen and (max-width: 976px) {
  h3,
  .h3 {
    font-size: 24px;
    line-height: 32px;
  }
}
h4,
.h4 {
  font-size: 24px;
  line-height: 32px;
}
@media only screen and (max-width: 976px) {
  h4,
  .h4 {
    font-size: 14px;
    line-height: 24px;
  }
}
h5,
.h5 {
  font-size: 14px;
  line-height: 24px;
}
@media only screen and (max-width: 976px) {
  h5,
  .h5 {
    font-size: 10.5px;
    line-height: 18px;
  }
}
p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #514468;
  margin-top: 18px;
}
@media only screen and (max-width: 976px) {
  p {
    font-size: 13.5px;
    line-height: 21px;
  }
}
@media only screen and (max-width: 767px) {
  p {
    font-size: 18px;
    line-height: 28px;
  }
}
strong,
a,
span {
  font-size: inherit;
}
.no-pagging {
  padding: 0 !important;
}
.margin-0-auto {
  margin: 0 auto;
}
a {
  color: #713ddd;
  font-size: inherit;
}
ol {
  list-style: decimal;
  padding-left: 24px;
}
ol li {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #514468;
}
@media only screen and (max-width: 976px) {
  ol li {
    font-size: 13.5px;
    line-height: 21px;
  }
}
@media only screen and (max-width: 767px) {
  ol li {
    font-size: 18px;
    line-height: 28px;
  }
}
.rtl .wp-block-cover-image .wp-block-cover__image-background,
.rtl .wp-block-cover-image video.wp-block-cover__video-background,
.rtl .wp-block-cover .wp-block-cover__image-background,
.rtl .wp-block-cover video.wp-block-cover__video-background {
  transform: rotateY(180deg);
}
.wp-block-uagb-info-box.purple-btn .wp-block-button__link {
  padding: 16px 28px;
  background: #713ddd;
  border: 1px solid #713ddd;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  box-shadow: 0 24px 38px -4px rgba(0,0,0,0.1);
  border-radius: 42px;
  position: relative;
  width: 100%;
  max-width: 328px;
}
@media only screen and (max-width: 767px) {
  .wp-block-uagb-info-box.purple-btn .wp-block-button__link {
    max-width: 343px;
  }
}
.wp-block-uagb-info-box.purple-btn .wp-block-button__link span {
  width: 100%;
}
.wp-block-uagb-info-box.purple-btn .wp-block-button__link:after {
  content: "";
  width: 40px;
  height: 38px;
  background: url("../img/btn-orange-arrow.svg") center no-repeat;
  top: 14px;
  right: 28px;
  position: absolute;
  display: inline-block;
  background-size: contain;
}
.wp-block-cover {
  padding: 0;
}
.lang-wrapper {
  position: relative;
}
.rtl .dropdown-content--lang {
  left: -60px !important;
}
.rtl .lang-item a img {
  border-radius: 50%;
  margin-left: 8px;
}
.padding-default {
  padding-top: 96px;
  padding-bottom: 96px;
}
@media only screen and (max-width: 976px) {
  .padding-default {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}
@media only screen and (max-width: 767px) {
  .padding-default {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
.rtl .s-feature__block-item-link img {
  transform: scaleX(-1);
}
.rtl .s-header__work-btn .play-wrap {
  margin-left: 16px;
  margin-right: inherit;
}
.rtl .s-header__started-btn .more-wrap {
  margin-right: 12px;
  margin-left: inherit;
  transform: scaleX(-1);
}
.rtl .s-header__work-btn {
  margin-right: 28px;
  margin-left: 0;
}
.rtl.page-template-blog .entry-meta {
  text-align: right;
}
.rtl.page-template-blog .s-posts__tab-result-block-text-wrap:after {
  transform: scaleX(-1);
  left: 0;
  right: auto;
}
.rtl.s-posts__tabs {
  margin-left: auto;
  margin-right: 0;
}
.rtl .modal {
  left: auto !important;
  right: 50%;
  transform: translate(50%, -50%) !important;
}
.rtl .collapsible li .collapsible-header {
  padding: 0 0 0 46px;
}
.rtl .collapsible li .collapsible-header__plus {
  left: 0;
  right: inherit !important;
}
.rtl .collapsible li .collapsible-body {
  padding: 8px 0 0 46px;
}
.rtl .s-header__arrow-right {
  left: -20px;
  right: inherit;
  transform: scaleX(-1);
}
@media only screen and (max-width: 1100px) {
  .rtl .s-header__arrow-right {
    left: -90px;
    right: inherit;
  }
}
@media only screen and (max-width: 600px) {
  .rtl .s-header__arrow-right {
    display: none;
  }
}
.rtl .s-header__arrow-left {
  left: 100px;
  right: inherit;
  transform: scaleX(-1);
}
@media only screen and (max-width: 1100px) {
  .rtl .s-header__arrow-left {
    left: -44px;
    right: inherit;
  }
}
@media only screen and (max-width: 600px) {
  .rtl .s-header__arrow-left {
    display: none;
  }
}
.rtl .s-header__arrow-heart {
  left: 40px;
  right: inherit;
  transform: scaleX(-1);
}
@media only screen and (max-width: 1100px) {
  .rtl .s-header__arrow-heart {
    left: -60px;
    right: inherit;
  }
}
@media only screen and (max-width: 600px) {
  .rtl .s-header__arrow-heart {
    left: 10px;
    right: inherit;
  }
}
